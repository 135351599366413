import React, {Component} from 'react';
import "./stylesheet/Github.css";
import {setNavigation} from "./NavBar";
import {Link} from "react-router-dom";

export class Github extends Component {
    static displayName = Github.name;

    constructor(props) {
        super(props);
        this.state = {repos: [], loading: true, sortValue: "create"};
        setNavigation("Projets");
    }

    componentDidMount() {
        this.populateGithubData();
    }
    handleSortChange(event) {
        this.setState({sortValue: event.target.value});
    }
    
    static displayDate(dateString) {
        let dt = new Date(dateString);
        
        return dt.toLocaleDateString()
    }
    static renderForecastsTable(repos) {
        if (repos.length === 0)
            return (
                <span className="dark:bg-amber-950 rounded-lg px-8 shadow-xl dark:text-white">
                    An error has occured.
                </span>
            )
        return (
            <div className="grid sm:grid-cols-3 md:grid-cols-4 gap-4 grid-cols-2 py-3">
                {
                    repos.map(repo =>
                        <div className="dark:bg-slate-800 rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl hover:bg-slate-600 group" key={repo.id}>
                                <span className="inline-flex justify-center p-1 bg-indigo-500 rounded-md shadow-lg">
                                    <img className="w-12 h-12 hover:blur" src={repo.owner.avatar_url} alt="logo"/>
                                </span>
                            <h3 className="ml-2 dark:text-white text-base font-medium tracking-tight inline">
                                <Link to={repo.html_url} target="_blank">{repo.name}</Link>
                            </h3>
                            <p className="dark:text-slate-400 mt-2 text-sm">
                                {repo.description}
                            </p>
                            <div className="tags flex bottom-0">
                                {this.DisplayLanguage(repo.language)}
                                <span className="bg-blue-100 font-medium my-8 mr-2 px-2.5 py-0.5 rounded text-blue-950 sm:overflow-hidden text-xs sm:text-sm whitespace-nowrap sm:whitespace-normal">
                                    {repo.stargazers_count} &#127775;
                                </span>
                                
                                <span className="bg-[#3f4148] text-sm font-medium my-8 mr-2 px-2.5 py-0.5 rounded text-white  hidden lg:inline" aria-label="Crée le">
                                    {Github.displayDate(repo.created_at)}
                                </span>
                                {
                                    (repo.archived === false ?"":<span className="bg-[#3f4148] text-sm font-medium my-8 mr-2 px-2.5 py-0.5 rounded text-orange-200 hidden xl:inline" aria-label="archived">Archivé</span>)
                                }
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
    static DisplayLanguage(language) {
        let color = "text-blue-950";
        switch (language){
            case "C#":
                color = "text-[#178600]";
                break;
            case "JS":
                color = "text-[#F7DF1E]";
                break;
            case "PHP":
                color = "text-[#764ABD]";
                break;
            case "HTML":
                //I know HTML is not a language, but it's for the example
                color = "text-[#E44B24]";
                break;
            case "JAVA":
                color = "text-[#B07219]";
                break;
            case "C++":
                color = "text-[#F33248]";
                break;
            case "GO":
                color = "text-[#00ADD8]";
                break;
            case "TS":
                color = "text-[#007ACC]";
                break;
            case "PY":
                color = "text-[#3572A5]";
                break;
                
        }
        return <>
         <span className={"bg-[#050f3d] text-sm font-medium my-8 mr-2 px-2.5 py-0.5 rounded "+color}>
            {language}
         </span>
        </>
    }
    render() {
        let contents = this.state.loading
            ?
            <p><em>Les données sont actuellement entrain d'être téléchargé! Si rien ne s'affiche dans les 10 prochaines
                secondes, rafraichissez la page!</em></p>
            : Github.renderForecastsTable(this.state.repos);

        return <>
            <div className="dark:bg-slate-950 rounded-lg p-4 my-3">
                <div className="projets-title py-4 text-6xl text-center dark:text-cyan-100">
                    <p>Mes projets</p>
                </div>
                <p className="dark:text-amber-50 text-2xl">
                    Voici une liste de mes projets personnels:
                </p>
                <br/>
                <div className="dark:text-amber-100 dark:bg-slate-800 rounded-lg px-2">
                    {this.EditPost()}
                </div>
                {contents}
            </div>
        </>;
    }
    handleSubmit = (e)=> {
        // Prevent the browser from reloading the page
        e.preventDefault();
        // Read the form data
        const form = e.target;
        const formData = new FormData(form);
        // You can pass formData as a fetch body directly:
        this.setState({loading: true});
        console.log("form",form);
        console.log("formData:",formData);
        fetch('/githubfetch', { method: form.method, body: formData })
            .then(response => response.json())
            .then(data =>{
                console.log("data",data);
                this.setState({repos: data, loading: false});
            })
            .catch(error => {
                console.error("Erreur lors de la requete",error)
            });
        // You can generate a URL out of it, as the browser does by default:
        console.log(new URLSearchParams(formData).toString());
    }
    EditPost=()=> {
        return (
            <form method="post" onSubmit={this.handleSubmit}>
                <label className="dark:text-amber-50">
                    Trier par:
                    <select name="sort" className="dark:text-amber-50 bg-slate-950 rounded mx-4">
                        <option value="create">Date de création</option>
                        <option value="stars">Stars</option>
                        <option value="update">Date de mise à jour</option>
                        <option value="name">Nom</option>
                        <option value="language">Language</option>
                    </select>
                </label>
                <button type="submit" className="btn bg-slate-400 hover:bg-black">Trier</button>
            </form>
        );
    }
    async populateGithubData() {
        const token = null;
        const response = await fetch('githubfetch');
        if (response.status !== 200) {
            console.log(response.status);
            this.setState({repos: [], loading: false});
            return;
        }
        const data = await response.json();
        this.setState({repos: data, loading: false});
    }
}
