import React from "react";

export class Modal extends React.Component{
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
    }
    handleOutsideClick = (event) => {
        // Checking id doesn't work, so I check if the class is the same
        if(event.target.classList.contains("inset-0"))
            this.props.onClose();
    };
    render(){
        const {isOpen, skill, onClose} = this.props;
        if(!isOpen) return null;
        
        return (
            <div
                className="fixed inset-0 flex justify-center items-center backdrop-blur-md z-50 bg-black bg-opacity-50"
                onClick={this.handleOutsideClick}
                id="outside-exit"
            >
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center backdrop-blur-md">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
                        <div className="flex justify-between items-center">
                            <h2 className="text-xl font-bold">{skill.name}</h2>
                            <img className="h-12 w-12 rounded-full" src={skill.logo} alt={skill.name + " logo"}
                                 style={{ backgroundColor: skill.color ? skill.color : '' }}/>
                        </div>
                        <hr className="my-4"/>
                        <p dangerouslySetInnerHTML={{__html: skill.info}}></p>
                        <hr className="my-4"/>
                        <button onClick={onClose} className="w-full text-center py-2 bg-blue-300 text-white rounded hover:bg-blue-950 transition-colors">Fermer</button>
                    </div>
                </div>
            </div>
        );
    }

}