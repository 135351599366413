import React, {Component} from 'react';
import {setNavigation} from "./NavBar";

export class Timeline extends Component {
    static displayName = Timeline.name;
    static datas = [];
    
    constructor(props) {
        super(props);
        setNavigation("Timeline");
        this.datas = [
            {
                title:"Baccalauréat STI2D",
                date:"01/09/2018 - 01/07/2020",
                description:"<em>Obtention du BAC Mention Bien</em><br/>Découverte d'Arduino, réalisation lors de la terminale d'un projet embarquée utilisant un Arduino."
            },
            {
                title: "DUT Informatique",
                date:"01/09/2020 - 01/01/2021",
                description:"Lors de mon semestre de DUT Informatique, j'ai pu apprendre à programmer en <code>JAVA</code> et à utiliser <code>Symfony</code>.<br/>J'ai aussi pu réaliser un projet tuteuré avec 3 camarades.<br/><a href=\"https://future.adel.uno\" target=\"_blank\">Site Web</a> | <a" +
                    "                                        href=\"https://github.com/Ade-l/ProjetTuto\" target=\"_blank\">Github</a>"
            },
            {
                title: "1<sup>ère</sup> année de <code>BTS SN:IR</code>",
                date: "02/09/2021 - 01/07/2022",
                description:"A la fin de mon premier semestre en <code>DUT Informatique</code>, j'ai décidé de me réorienter vers un <code>BTS Système Numérique</code> Option: <code>Informatique et réseaux</code> au lycée Jules Haag à Besançon.<br/>" +
                    "Lors de ce BTS j'ai pu concentrer mon apprentissage sur les langages <code>C</code>, <code>C++</code>, <code>Python</code> et l'utilisation du système d'exploitation <code>Linux</code>."
            },
            {
                title: "Stagiaire Téléphonie et réseaux",
                date: "23/05/2022 - 01/07/2022 (6 semaines)",
                description: "Réalisation d'un stage d'une durée de 6 semaines chez <a href=\"https://www.abctelephonie.com/\">ABC Téléphonie.</a><br/>Lors de ce stage j'ai pu réaliser de la maintenance sur du matériel réseaux et effectuer du R&D sur de nouvelles solutions réseaux.<br/>Ce stage m'a permis de découvrir le fonctionnement de la téléphonie en réseaux et la pratique des cours théoriques."
            },
            {
                title: "2<sup>ème</sup> année de <code>BTS SN:IR</code>",
                date: "01/09/2022 - 07/07/2023",
                description: "Lors de ma deuxième année de BTS, j'ai été ammené à réaliser un projet de fin d'année qui était présenté à un jury.<br/>Le projet consistait en la conception d'un vidéoprojecteur automobile, dans ce projet je devais m'occuper du traitement de l'image via <code>OpenCV</code> et <code>FFMPEG</code>.<br/>J'ai aussi pu obtenir une certification <code>CISCO</code>.<br/><em>Classement: 1<sup>er</sup>/19</em>"
            },
            {
                title: "Obtention du <code>BTS SN:IR</code>",
                date: "31/06/2023",
                description: "Désormais titulaire du <code>BTS Système Numérique: Informatique et réseaux</code>, je réfléchis à une poursuite d'étude."
            },
            {
                title: "Technicien Informatique +",
                date: "15/12/2023 - 31/07/2024 (CDD de 7 mois)",
                description: "Actuellement technicien informatique chez la MSA de Franche Comté, je suis en charge de la maintenance du parc informatique de la MSA.<br/>En tant que technicien, j'ai été emmené à réaliser différents projets:<br>tel que l'installation et configuration de borne wifi Aruba, mise en place d'une charte CSSI, d'une solution de vidéo surveillance."
            },
            {
                title: "Bachelor 3<sup>ème</sup> année Informatique",
                date: "01/10/2024 - 01/08/2025",
                description: "J'ai pour ambition de rejoindre le <code>Bachelor concepteur développeur d'applications en <b>alternance</b></code> au <code>CESI de DIJON</code>."
            }
        ];
    }


    render() {
        let datas = this.datas.reverse();
        console.log(datas);
        return <>
            <div>
                <h1 className="text-3xl text-center py-3 text-[#e8bfbf]">Ma timeline</h1>

                <div className="min-h-screen flex items-center justify-center">
                    <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2">
                        {
                            datas.map((data, index) => {
                                console.log("INDEx: "+index);
                                console.log(data)
                                if(index % 2 === 0) {
                                    console.log("PAIR");
                                    return(
                                        <div className="flex md:contents">
                                            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
                                                <div className="h-full w-6 flex items-center justify-center">
                                                    <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                                                </div>
    
                                                <div
                                                    className="w-6 h-6 absolute top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-red-100 shadow"></div>
                                            </div>
    
                                            <div
                                                className="w-full col-start-6 col-end-10 my-4 mr-auto p-4 border border-gray-300 rounded-xl bg-[#1f2937]">
                                                <h3 className="font-semibold text-xl mb-1 dark:text-white" dangerouslySetInnerHTML={{__html:data["title"]}}></h3>
                                                <h5 className="text-amber-100" dangerouslySetInnerHTML={{__html:data["date"]}}></h5>
                                                <p className="dark:text-amber-50" dangerouslySetInnerHTML={{__html:data["description"]}}>
                                                </p>
                                            </div>
                                        </div>
                                    );
                                } 
                                else {
                                    return(
                                        <div className="flex flex-row-reverse md:contents">
                                            <div
                                                className="w-full col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto border border-gray-300 bg-[#1f2937] group">
                                                <h3 className="font-semibold text-xl mb-1 dark:text-white" dangerouslySetInnerHTML={{__html:data["title"]}}></h3>
                                                <h5 className="text-amber-100" dangerouslySetInnerHTML={{__html:data["date"]}}></h5>
                                                <p className="dark:text-amber-50" dangerouslySetInnerHTML={{__html:data["description"]}}>
                                                </p>
                                            </div>
    
                                            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
                                                <div className="h-full w-6 flex items-center justify-center">
                                                    <div className="h-full w-0.5 bg-gray-200 pointer-events-none"></div>
                                                </div>
    
                                                <div
                                                    className="w-6 h-6 absolute top-1/2 -mt-3 border-2 border-blue-500 rounded-full bg-red-100 shadow"></div>
                                            </div>
                                        </div>
                                    );
                                }
                            })
                        }
                        
                        
                    </div>
                </div>
            </div>
        </>
    }
}
