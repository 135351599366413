import {Component, useState, useEffect} from "react";
import {setNavigation} from "./NavBar";
import {Modal} from "./standalone/ModalForm";
export class Skills extends Component{
    static skillsData = [];
    constructor(props) {
        super(props);
        setNavigation("Skills")
        this.skillsData = [
            {
                "category": "Langages",
                "skills": [
                    {
                        "name": "JavaScript",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6a/JavaScript-logo.png/768px-JavaScript-logo.png",
                        "info": "Le Javascript a été l'un de mes premiers langages, que j'ai rencontré en m'intéréssant au développement web, j'ai pu pratiquer le langage sous diffrentes formes.<br><em>Pure</em>: accompagné d'un fichier HTML et CSS (Procédé des requêtes XHR, AJAX), avec Node.Js<br/><em>Complexe</em>: en utilisant des frameworks tel que React, Vue.js"
                    },
                    {
                        "name": "C#",
                        "logo": "https://play-lh.googleusercontent.com/uGqP7F-E_eaEwTb3hMz63MWf0YKRSK6n9INBwibBSOrGDg6B3sd-ACuqNrR312ohdQ",
                        "info": "Le C# est le langage que j'ai appris en autodidacte, j'ai pu réaliser de nombreux projets avec ce langage, que ce soit des applications de bureau, des sites web ou des bots Discord.<br />J'ai pu m'essayer à la création de jeux vidéos avec Unity, j'ai pu réaliser un petit jeu de plateforme en 2D.<br/> Mes compétences en C# se résument plutôt à la création d'application dite \".NET Core\""
                    },
                    {
                        "name": "PHP",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/2/27/PHP-logo.svg",
                        "info": "Le PHP fut ma première expérience dans le développement backend, que j'ai appris en autodidacte grâce à OpenClassrooms et leurs tutoriels.<br />PHP est le langage de programmation utilisé pour le premier serveur Minecraft <s>Pocket Edition</s> Bedrock Edition, appelé <a href='https://pmmp.io/'>PMMP</a>. J'ai consacré de nombreuses heures à la création de plugins en PHP, en liaison avec des bases de données ou des fichiers.<br />Durant cette période, j'ai également pu créer un serveur avec un système complet et fonctionnel."
                    },
                    {
                        "name": "C++",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/1/18/ISO_C%2B%2B_Logo.svg",
                        "info": "J'ai pu m'exercer sur le C++ lors de mon BTS SN:IR, j'ai pu réaliser quelques projets tels que un système client-serveur (en utilisant des pipes, des sockets).<br />J'ai aussi pu m'essayer au Reverse Engineering et à la modification de valeur d'adresse avec c++."
                    },
                    {
                        "name": "Python",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Python_logo_51.svg/1200px-Python_logo_51.svg.png",
                        "info": "J'ai pu m'exercer sur ce langage lors de mon parcours scolaire, en général je limite mon utilisation de python à la création de petits scripts<br />Un exemple concret de mon utilisation de python est la création de script me permettant de rechercher et trier du texte dans un fichier."
                    },
                    {
                        "name": "SQL",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/8/87/Sql_data_base_with_logo.png",
                        "info": "J'ai complété mon apprentissage du PHP par celui du SQL. <br /> J'ai acquis des compétences dans la création de tables complexes (avec des clés étrangères et des jointures) ainsi que dans la manipulation des données (INSERT, UPDATE, DELETE)."
                    },
                    {
                        "name": "Java",
                        "logo": "https://www.java.com/_cache_8a03/_themesdelivery/JCOM_Base_Theme/assets/img/Java-horz-wht.svg",
                        "info": "Mon apprentissage du Java a été en partie autodidacte, complété par une période de mon parcours scolaire, durant laquelle j'ai eu l'opportunité de m'exercer à la réalisation de tests en Java en utilisant des outils comme JUnit et Mockito. Cette expérience m'a permis de renforcer mes compétences en tests unitaires et en développement logiciel, en comprenant l'importance des tests pour garantir la fiabilité et la maintenabilité du code.<br> J'ai également exploré des concepts avancés tels que le mock testing et l'intégration continue, contribuant ainsi à une meilleure qualité de mes projets.",
                        "color": "#007396"
                    }
                    
                ]
            },
            {
                "category": "Technologies",
                "skills": [
                    {
                        "name": "React",
                        "logo": "https://cdn.worldvectorlogo.com/logos/react-1.svg",
                        "info": "Ce site web a été réalisé avec React, il utilise le principe de composants avec les Modals.<br />J'ai aussi pu m'essayer à React Native, bien que je n'ai pas réalisé de projet concret."
                    },
                    {
                        "name": "ASP.NET",
                        "logo": "https://play-lh.googleusercontent.com/uGqP7F-E_eaEwTb3hMz63MWf0YKRSK6n9INBwibBSOrGDg6B3sd-ACuqNrR312ohdQ",
                        "info": "Je me suis lancé dans le développement avec le langage C# il y a de ça 4 ans, j'ai donc forcément du passer par la case ASP.NET, j'ai pu réaliser différents projets tel que le backend de ce site qui est utilisé pour la page projet (requête Github), un site web basique pour des requêtes CRUD sur une base de données Microsoft SQL.<br />J'ai aussi pu essayer le CMS <a href='https://umbraco.com/'>Umbraco</a>, qui fut une plutôt bonne découverte, malheureusement je n'ai aucun cas concret à démontrer"
                    },
                    {
                        "name": "Symfony",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/6/60/Symfony2.svg/800px-Symfony2.svg.png",
                        "info": "J'ai pu travailler avec Symfony lors de mon semestre de DUT Informatique, j'ai pu réaliser un site web pour un projet tuteuré avec 3 camarades.<br />J'ai aussi pu réaliser quelques sites web personnels pour m'expérimenter."
                    },
                    {
                        "name": "Laravel",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Laravel.svg/1200px-Laravel.svg.png",
                        "info": "Lors de mon semestre de DUT, j'ai eu à choisir entre Symfony et Laravel, e me suis alors penché sur Laravel pour découvrir ses avantages, j'ai pu réaliser quelques petits sites (CRUD) pour m'expérimenter."
                    },
                    {
                        "name": "Node.js",
                        "logo": "https://upload.wikimedia.org/wikipedia/commons/d/d9/Node.js_logo.svg",
                        "info": "Je me suis essayé à Node.JS dans différentes situations, dans mes premiers temps, pour la création de Bot Discord, NodeJs étant le choix le plus répandu à ce moment là, j'ai pu découvrir le principe de node.js lors de la création de ce bot.<br />J'ai aussi pu m'essayer à la création de site web avec Express.js, un de mes objectifs était de crée un \"reversed proxy\" pour héberger plusieurs site sur le même serveur."
                    },
                    {
                        "name": "WPF",
                        "logo": "https://www.ambient-it.net/wp-content/uploads/2016/04/wpf-logo-175.png",
                        "info": "Dans ma découverte du C#, je me suis penché sur WPF pour découvrir le fonctionnement des applications Windows, j'ai pu réaliser quelques applications de bureau pour m'expérimenter."
                    }
                    
                ]
            }
        ];
        this.state = {
            selectedSkill: null,
            isModalOpen: false
        }
    }

    openSkillModal = (skill)=>{
        //Still working on it....
        this.setState({selectedSkill: skill, isModalOpen: true});
    }
    
    closeSkillModal = ()=>{
        this.setState({selectedSkill: null, isModalOpen: false});
    }
    
    render() {
        const { selectedSkill, isModalOpen } = this.state;
        return (
            <div className="p-8">
                {
                    this.skillsData.map((category, index) => (
                    <div key={index} className="mb-10">
                        <h2 className="text-2xl font-bold mb-6 text-amber-200">{category.category}</h2>
                        <div className="flex flex-wrap gap-6 justify-start items-center">
                            {
                                
                                category.skills.map((skill, skillIndex) => (
                                    <div key={skillIndex} className="group" onClick={()=> this.openSkillModal(skill)} data-dialog-target="dialog">
                                        <div className="w-24 h-24 bg-gray-100 flex items-center justify-center overflow-hidden rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out"
                                             style={{ backgroundColor: skill.color ? skill.color : '' }}>
                                            <img src={skill.logo} alt={skill.name + " logo"} className="group-hover:scale-125 transition-transform duration-300 ease-in-out {}" />
                                        </div>
                                        <p className="text-center mt-2 group-hover:text-lg transition-all duration-300 ease-in-out text-amber-100">{skill.name}</p>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    ))
                }
                <Modal isOpen={isModalOpen} skill={selectedSkill} onClose={this.closeSkillModal}/>
            </div>
        );
    }
}