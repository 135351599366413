import React from "react";

export class TicTacToe extends React.Component{

    constructor(props) {
        super(props);
        this.matrice = [
            [0, 0, 0],
            [0, 0, 0],
            [0, 0, 0]
        ];
        this.state = {
            player: 0,
            winner: 0,
            time: 0
        }
    }
    checkResult(){
        // No winner if player didnt played 3 times
        if(this.state.time <4) return;
        // Check rows
        console.log("Checking rows!")
        for(let i = 0; i < 3; i++){
            if(this.matrice[i][0] === this.matrice[i][1] && this.matrice[i][1] === this.matrice[i][2] && this.matrice[i][0] !== 0){
                this.setWinner(this.matrice[i][0]);
                return;
            }
        }
        // Check columns
        console.log("Checking columns!")
        for(let i = 0; i < 3; i++){
            if(this.matrice[0][i] === this.matrice[1][i] && this.matrice[1][i] === this.matrice[2][i] && this.matrice[0][i] !== 0){
                this.setWinner(this.matrice[0][i]);
                return;
            }
        }
        // Check diagonals
        console.log("Checking diagonals!")
        if(this.matrice[0][0] === this.matrice[1][1] && this.matrice[1][1] === this.matrice[2][2] && this.matrice[0][0] !== 0){
            this.setWinner(this.matrice[0][0]);
            return;
        }
        if(this.matrice[0][2] === this.matrice[1][1] && this.matrice[1][1] === this.matrice[2][0] && this.matrice[0][2] !== 0){
            this.setWinner(this.matrice[0][2]);
            return;
        }
        //checking score null
        console.log("No winner yet! with time: " + this.state.time);
        if(this.state.time === 8){
            console.log("Match nulle!");
            this.setWinner("draw");
            return;
        }
    }
    setWinner(winner){
        this.setState({winner: winner});
        
    }
    userInput(row, column){
        console.log(row, column);
        if(this.matrice[row][column] !== 0) return;
        if(this.state.winner !== 0) return;
        this.setState({time: this.state.time + 1});
        this.matrice[row][column] = this.state.player === 0 ? 1 : -1;
        this.setState({player: this.state.player === 0 ? 1 : 0});
        this.checkResult();
        console.log("Fin de tour");
    }
    renderMatrice() {
        return this.matrice.map((row, i) => {
            return <div key={i} className="flex">
                {row.map((cell, j) => {
                    return <div key={j} className="border-2 border-black w-12 h-12 flex justify-center items-center" onClick={()=> this.userInput(i,j)}>{cell < 0 ? "X" : (cell > 0?"O":"")}</div>
                })}
            </div>
        });
    }
    //Add '0' at the end of page #bug
    render(){
        return <>
            <span>Coups joué: {this.state.time}</span>
            <div id={"table_game"}>
                {this.renderMatrice()}
            </div>
            {this.state.winner &&(
                this.state.winner === "draw"
                    ? <span>Match nul!</span>
                    : <span>Le joueur {this.state.winner === 1 ? "O" : "X"} a gagné!</span>
            )}
        </>
    }

}