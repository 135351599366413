import {Component} from "react";
import {TicTacToe} from "./standalone/TicTacToe";

export class Game extends Component{
    static displayName = Game.name;

    constructor(props) {
        super(props);
    }

    render(){
        return <>
            <h1>Tic Tac Toe!</h1>
            <h3>Les croix commencent!</h3>
            <TicTacToe />
        </>
    }
}