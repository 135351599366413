import { Component } from "react";
import { setNavigation } from "./NavBar";

export class Contact extends Component {
    static displayName = Contact.name;

    constructor(props) {
        super(props);
        setNavigation("Contact");
        this.state = {
            secondsLeft: 5
        };
    }

    componentDidMount() {
        this.countdown = setInterval(() => {
            this.setState(prevState => {
                if (prevState.secondsLeft > 1) {
                    return { secondsLeft: prevState.secondsLeft - 1 };
                } else {
                    clearInterval(this.countdown);
                    window.open('https://www.linkedin.com/in/adel-mhaia', '_blank');
                    return { secondsLeft: 0 };
                }
            });
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.countdown);
    }

    render() {
        return (
            <>
                <div className="dark:bg-slate-800 rounded-lg py-4 text-center mt-1">
                    <span className="dark:text-red-400 text-6xl">Contact</span>
                </div>
                
                <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 mt-[64px]">
                    <div className="bg-slate-700 p-6 rounded-lg shadow-lg text-center">
                        <h2 className="text-xl font-semibold mb-4 dark:text-white">Redirection en cours</h2>
                        <p className="mb-4 dark:text-gray-300">
                            Vous allez être redirigé vers mon profil LinkedIn dans {this.state.secondsLeft} secondes.
                        </p>
                        <p className="dark:text-gray-400">Merci de votre patience.</p>
                    </div>
                </div>
            </>
        );
    }
}
