import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import NavBar from './NavBar';
import Footer from './Footer';

export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    return (
      <div>
        <NavBar />
          {/* min-h-screen => fix footer*/}
        <Container tag="main" className="min-h-screen">
          {this.props.children}
        </Container>
          <Footer />
      </div>
    );
  }
}
