import {Component} from "react";
import axios from 'axios';
export class ImagePage extends Component{
    static displayName = ImagePage.name;

    constructor(props) {
        super(props);
        this.state = {
            file: null,
            downloadLink: null,
            loading: false
        };
    }
    
    handleFileChange = (event) => {
        this.setState({
            file: event.target.files[0],
            downloadLink: null
        });
    }
    
    handleUpload = async () => {
        const {file} = this.state;
        if(!file) return;
        
        const formData = new FormData();
        formData.append("file", file);
        
        this.setState({loading: true});
        
        try{
            const response = await axios.post("/imageconversion", formData, {
                responseType: "blob"
            });
            
            const imageBlob = URL.createObjectURL(response.data);
            this.setState({downloadLink: imageBlob});
        }catch(error) {
            console.error("Erreur lors de la conversion de l'image", error);
        }finally {
            this.setState({file: null, loading: false});
        }
    }
    render(){
        return <>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="file_input">Fichier à "transformer" en png</label>
            <input type="file" onChange={this.handleFileChange} accept=".webp, .jfif"
                   className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                   aria-describedby="file_input_help" id="file_input"/>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300" id="file_input_help">SVG, PNG, JPG or GIF (MAX.
                800x400px).</p>
            <button onClick={this.handleUpload} disabled={this.state.loading || !this.state.file} className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800 disabled:bg-gray-400 disabled:cursor-not-allowed disabled:hover:bg-gray-500">
                {this.state.loading ? 'Conversion...' : 'Transition'}
            </button>


            {this.state.loading && <p>Votre fichier est en cours de conversion.</p>}
            {this.state.downloadLink && (
                <div>
                    <h3>Résultat:</h3>
                    <a href={this.state.downloadLink} download="image.png">Télécharger le rendu!</a>
                </div>
            )
                
            }
        </>
    }
}